import React from "react";

function Success() {
  return (
    <div class="full-page-success">
      <div class="success-content">
        <i class="fas fa-check-circle"></i>
        <h1>Success!</h1>
        <p>Image upload successfully.</p>
      </div>
    </div>
  );
}

export default Success;
