import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { history } from "./utils/history";

import LiqaWebView from "./component/LiqaWebView";
import Success from "./component/Success";
import Error from "./component/Error";
import NotFound from "./component/NotFound";

const AppRoute = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/:userId" component={LiqaWebView} />
        <Route exact path="/success/:userId" component={Success} />
        <Route exact path="/error/:userId" component={Error} />
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default AppRoute;
