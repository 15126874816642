import React from "react";

function Error({ title }) {
  return (
    <div class="full-page-error">
      <div class="error-content">
        <i class="fas fa-times-circle"></i>
        <h1>Error!</h1>
        <p>{title || "Something went wrong. Please try again later."}</p>
      </div>
    </div>
  );
}

export default Error;
