import React from "react";

function Loader({ title = "" }) {
  return (
    <div className="fullscreen-loader">
      <div className="loader-wrapper">
        <div className="loader"></div>
        <p>{title || "Loading..."}</p>
      </div>
    </div>
  );
}

export default Loader;
