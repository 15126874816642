export const LoadingStatus = {
    TOLOAD: "toload",
    LOADING: "loading",
    LOADED: "loaded",
};

export const Languages = {
    ENGLISH: "en",
    GERMAN: "de",
    DUTCH: "nl",
    POLISH: "pl",
    LITHUANIAN: "lt",
    JAPANESE: "ja"
}